import React from "react";

export const StackOverflow = ({ color }) => (
  <svg viewBox="0 0 120 120">
    <path d="M84.4 93.8V70.6h7.7v30.9H22.6V70.6h7.7v23.2z" fill={color} />
    <path
      d="M38.8 68.4l37.8 7.9 1.6-7.6-37.8-7.9-1.6 7.6zm5-18l35 16.3 3.2-7-35-16.4-3.2 7.1zm9.7-17.2l29.7 24.7 4.9-5.9-29.7-24.7-4.9 5.9zm19.2-18.3l-6.2 4.6 23 31 6.2-4.6-23-31zM38 86h38.6v-7.7H38V86z"
      fill={color}
    />
  </svg>
);
