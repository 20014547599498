import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Wrapper } from './styles'

export default ({ desktop }) => (
	<Wrapper desktop={desktop}>
		<AnchorLink href="#about">关于</AnchorLink>
		<AnchorLink href="#contact">联系我们</AnchorLink>
	</Wrapper>
)
