module.exports = {
  defaultTitle: "双成文化",
  logo: "https://gatsby-theme-portfolio.smakosh.com/favicon/favicon-512.png",
  author: "",
  url: "http://www.changjingwenhua.com",
  legalName: "广州双成文化传播有限公司",
  defaultDescription: "让内心更多力量，让生活更多希望",
  socialLinks: {
    // twitter: "http://www.twitter.com/smakosh",
    // github: "https://github.com/smakosh",
    // linkedin: "https://www.linkedin.com/in/ismail-ghallou-630149122/",
    // instagram: "https://instagram.com/smakosh19",
    // youtube: "https://www.youtube.com/user/smakoshthegamer",
    // google: "https://plus.google.com/u/0/+IsmailSmakoshGhallou",
    // telegram: "https://t.me/smakosh",
    // stackOverflow: "https://stackoverflow.com/users/story/7396786"
  },
  googleAnalyticsID: "",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    // facebook: "appId",
    // twitter: "@smakosh"
  },
  address: {
    city: "City",
    region: "Region",
    country: "Country",
    zipCode: "ZipCode"
  },
  contact: {
    email: "email",
    phone: "phone number"
  },
  foundingDate: "2019",
  recaptcha_key: "6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN"
};
