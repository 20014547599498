import SEO from 'components/common/SEO';
import Intro from 'components/landing/Intro';
import Projects from 'components/landing/Projects';
import Skills from 'components/landing/Skills';
import Contact from 'components/landing/Contact';
import Footer from 'components/theme/Footer';
import * as React from 'react';
export default {
  SEO,
  Intro,
  Projects,
  Skills,
  Contact,
  Footer,
  React
};