import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Flex, Item } from "react-flex-ready";
import Container from "components/common/Container";
import Card from "components/common/Card";
import starIcon from "assets/icons/star.svg";
import forkIcon from "assets/icons/fork.svg";
import { Wrapper, Content, Stats } from "./styles";

export default () => {
  // const {
  //   github: {
  //     viewer: {
  //       repositories: { edges }
  //     }
  //   }
  // } = useStaticQuery(
  //   graphql`
  //     {
  //       github {
  //         viewer {
  //           repositories(
  //             first: 8
  //             orderBy: { field: STARGAZERS, direction: DESC }
  //           ) {
  //             edges {
  //               node {
  //                 id
  //                 name
  //                 url
  //                 description
  //                 stargazers {
  //                   totalCount
  //                 }
  //                 forkCount
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  return (
    <Wrapper as={Container} id="projects">
    </Wrapper>
  );
};
